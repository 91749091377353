<template>
  <div class="w1092">
    <div class="mybetwrap">
      <div class="boardName">
        <h2><span><img src="@/assets/img/menu/menu_cash_off_w.svg"></span>회원정보수정<em>Modify</em></h2>
      </div>
      <div class="setWrap">
        <p class="myfix"><span><img src="@/assets/img/icon/icon_set.svg"></span>회원정보 수정하기</p>
        <div class="myfixwrap">
          <ul class="joinu">
            <li>{{$t('front.common.memId')}}</li>
            <li class="myfix_id">{{userData.memId}}</li>
          </ul>
          <ul class="joinu">
            <li>{{$t('front.common.password')}}</li>
            <li><input type="password" placeholder="" v-model="model.memPass"></li>
          </ul>
          <span class="joinutext">{{$t('front.signup.passwordPlaceholder')}}</span>
          <ul class="joinu">
            <li>{{$t('front.mypage.passwordOk')}}</li>
            <li><input type="password" placeholder="" v-model="model.memPassCheck"></li>
          </ul>
          <ul class="joinu">
            <li>{{$t('front.common.cashOutPass')}}</li>
            <li><input type="text" id="cashOutPass"  maxlength="4"  pattern="[0-9]*" v-model="model.cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  inputmode="numeric"  placeholder="" /></li>
          </ul>
          <span class="joinutext">{{$t('front.signup.cashOutPassPlaceholder')}}</span>
          <ul class="joinu">
            <li>{{$t('front.mypage.ExchangepasswordOk')}}</li>
            <li><input  type="text" id="cashOutPassCheck" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="4"  pattern="[0-9]*" inputmode="numeric"  placeholder="" v-model="model.cashOutPassCheck"></li>
          </ul>
          <div class="joinubottom">
            <ul class="joinu">
              <li>{{$t('front.common.level')}}</li>
              <li>{{$t('front.common.level')}} LV. {{userData.memLevel}}</li>
            </ul>
            <ul class="joinu">
              <li>{{$t('front.gnb.casino')}}</li>
              <li>{{$t('front.common.level')}} LV. {{userData.casinoLevel}}</li>
            </ul>
            <ul class="joinu">
              <li>{{$t('front.gnb.slot')}}</li>
              <li>{{$t('front.common.level')}} LV. {{userData.slotLevel}}</li>
            </ul>
            <!--ul class="joinu">
              <li>미니게임</li>
              <li>레벨 LV. {{userData.mgLevel}}</li>
            </ul-->
          </div>
        </div>
      </div>
      <div class="boardbtn"><a class="rdsbtn" @click="onUpdate">{{$t('front.board.edit')}}</a></div>
    </div>
  </div>
</template>

<script>
import { isValidOnlyNumber, isValidPassword } from '@/libs/utils'
import { memberUpdate } from '@/api/member'

export default {
  name: 'MyPageInfo',
  data () {
    return {
      model: {
        memPass: '',
        cashOutPass: '',
        cashOutPassCheck: '',
        memPassCheck: ''
      }
    }
  },
  methods: {
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.toString().length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }

        if (data.cashOutPass !== data.cashOutPassCheck) {
          this.onAlert('warningart', 'front.member.compareCashPassCheck')
          return false
        }
      }

      return true
    },
    onUpdate () {
      if (this.isValidate()) {
        const param = {
          memId: this.userData.memId,
          memPass: this.model.memPass,
          cashOutPass: this.model.cashOutPass
        }

        memberUpdate(param).then(response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('front.member.completeUpdate')
          } else {
            this.onCheck('api.' + result.resultCode)
          }

          this.model = {
            memPass: '',
            cashOutPass: '',
            cashOutPassCheck: ''
          }
        })
      }
    }
  }
}
</script>
<style scoped>
#cashOutPass, #cashOutPassCheck {
  -webkit-text-security: disc;
}
.boardName {margin-bottom: 20px;}
.setWrap{width: 650px; margin: 0 auto;}
.myfix {font-size: 14px; font-weight: 600;line-height: 1.5em;margin: 15px 0 30px;color: #121212; display: flex; align-items: center; gap: 5px;}
.myfix>span {display: flex; justify-content: center; align-items: center;width: 24px; height: 24px;background-image: linear-gradient(to bottom, #73aa2c, #8fd03f); border-radius: 100%;}
.myfix>span>img{width: 70%;}
.myfixwrap {margin-top: 30px;}
.joinu {display: flex;gap: 8px; flex-direction: column;}
.joinu li:first-child {width: 150px;color: #121212;font-weight: bold; background: none;}
.joinu li {position: relative;display: flex;align-items: center;font-size: 16px;color: #464c5a; width: 100%; background: #f4f7ff; height: 50px;  border-radius: 10px;}
.joinu li.myfix_id { width: 30%; padding-left: 20px;}
.joinutext {font-size: 14px;color: #121212;padding: 8px 0 0 0px;text-align: left;display: block; }
.joinu input{padding: 0 0 0 20px;height: 50px;border: 0;width: 100%;font-size: 16px;background: #f4f7ff;color: #464c5a; border-radius: 10px;}
.joinubottom { border: 1px solid #eaedff; border-radius: 10px; margin: 20px 0; padding: 20px;}
.joinubottom .joinu {gap: 0; width: 60%; margin: 0 auto; flex-direction: row;}
.joinubottom .joinu li {color: #121212; background: none;}
.joinubottom .joinu li:first-child {width: 180px;font-weight: bold;}
.joinubottom .joinu li:last-child {justify-content: flex-end;}
.joinubottom .joinu li:first-child::after {content: none;}
.boardbtn {display: flex;justify-content: center;align-items: center;}
.boardbtn a {background-image: linear-gradient(to bottom, #73aa2c, #8fd03f);border-radius: 10px;width: 320px;height: 40px;padding: 0;border: 0;color: #fff;font-weight: 500;display: flex;align-items: center;justify-content: center;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
